import React, { useState } from 'react'

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import ReactPlayer from 'react-player/youtube'


const Shows = () => {

  const [video, newVideo] = useState('https://www.youtube.com/watch?v=HXtH7VcxjG4');

  const renderLoadButton = (id, label, label2) => {
    return (
      <button onClick={() => newVideo('https://www.youtube.com/watch?v=' + id)} className={style.videoButton}>
        <img src={'https://i.ytimg.com/vi/' + id + '/hqdefault.jpg'} alt={label}/>
        <span>{label}<br/><small>{label2}</small></span>
      </button>
    )
  }

  return (

    <Layout>

      <Seo title="Shows" bodyClass='st' />

   

      <div className="column column--100">

        <div className={style.video}>
          <ReactPlayer
            // ref={this.ref}
            className='react-player'
            width='100%'
            height='100%'
            url={video}
            // playing
          />
        </div>
        <h1 className={style.titleLowercase}>Shows</h1>
        <p>A versatile, unique sound! Check out some of T’s live performances.</p>
        <div className={style.videoList}>
          {renderLoadButton('HXtH7VcxjG4', 'HER NYE 2023 (Virtual)')}
          {renderLoadButton('kW1SZA6ro5o', '#SpecyalKeys Compilation (Various Genres)')}
          {renderLoadButton('JiVKs2nP-jU', 'Specyal T Live','@ Free Times Cafe')}
          {renderLoadButton('s5kAI8iChzs', 'Specyal Keys with The NC’s Live','@ The Painted Lady')}
          {renderLoadButton('yPDMWU4wLmM', 'Specyal Keys with The NC’s Live','@ Lee’s Palace')}
          {renderLoadButton('-nPOA2ULiGo', 'Chasing Sunday Live','@ The Tennessee ')}
          {renderLoadButton('1UrtFpQHzLI', 'Chasing Sunday Live','@ RF Sauga (Paper Cuts)')}
          {renderLoadButton('7RM1NS2N47c', 'Chasing Sunday Live In Rehearsal','(Later Tonite)')}

        </div>

      </div>


    </Layout>
  )
}
export default Shows